import React, { useState, useEffect, useContext } from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { AuthContext } from '../components/Auth/Auth';
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase"; // Your Firestore initialization
import Sidenav from "../components/NavBars/Sidenav";
import ResponsiveAppBar from "../components/NavBars/ResNav";

export default function Eduniverse() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from Firestore when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'eduniverse'));
        const fetchedData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <ResponsiveAppBar />
      <Box sx={{ display: "flex", height: "100%" }}>
        <Sidenav />
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Typography variant="h4">Eduniverse Collection</Typography>
          <Grid container spacing={2}>
            {data.map(item => (
              <Grid item xs={12} sm={6} md={2} m={2} key={item.id}>
                <Box border={1} padding={2} borderRadius={2}>
                  <Typography variant="h6">{item.name}</Typography>
                  <Typography variant="body1">{item.description}</Typography>
                  {/* Add more fields as necessary */}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
