import React, { useState, useContext, useEffect } from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Sidenav from "../components/NavBars/Sidenav";
import ResponsiveAppBar from "../components/NavBars/ResNav";
import Loading from "../components/commonComponents/Loading";
import { MenuItem, Select, InputLabel, FormControl, Checkbox, ListItemText, Typography } from "@mui/material";

import { AuthContext } from '../components/Auth/Auth';
import { collection, getDocs, addDoc, doc, setDoc, query, where } from "firebase/firestore";
import { db } from "./firebase"; // Your Firestore initialization

const categories = ["Music", "Drama", "Sports", "Games", "Other"];

export default function Clubs() {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [open, setOpen] = useState(false);
  const [newClub, setNewClub] = useState({
    name: '',
    url: '',
    category: '',
    owner: currentUser.email
  });
  const [userMemberships, setUserMemberships] = useState([]);

  // Fetch clubs and memberships from Firestore on component mount
  useEffect(() => {
    const fetchClubs = async () => {
      setLoading(true);
      try {
        const clubsCollection = collection(db, "clubs");
        const clubSnapshot = await getDocs(clubsCollection);
        const clubList = clubSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClubs(clubList);
        setFilteredClubs(clubList);

        // Fetch memberships
        const membershipsCollection = collection(db, "memberships");
        const membershipsQuery = query(membershipsCollection, where("user", "==", currentUser.email));
        const membershipsSnapshot = await getDocs(membershipsQuery);
        const membershipsList = membershipsSnapshot.docs.map(doc => doc.data());
        setUserMemberships(membershipsList);
      } catch (error) {
        console.error("Error fetching clubs or memberships:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClubs();
  }, [currentUser.email]);

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setSelectedCategories(value);
    setFilteredClubs(
      clubs.filter(club => value.length === 0 || value.includes(club.category))
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewClub(prevClub => ({ ...prevClub, [name]: value }));
  };

  const handleCreateClub = async () => {
    if (newClub.name && newClub.url && newClub.category) {
      try {
        // Add new club to Firestore
        const docRef = await addDoc(collection(db, "clubs"), newClub);
        console.log("Document written with ID: ", docRef.id);

        // Add membership with role owner
        await setDoc(doc(db, "memberships", `${currentUser.email}_${docRef.id}`), {
          user: currentUser.email,
          clubId: docRef.id,
          role: "owner",
          dateJoined: new Date()
        });

        // Update local state with the new club
        const updatedClubs = [...clubs, { ...newClub, id: docRef.id }];
        setClubs(updatedClubs);
        setFilteredClubs(updatedClubs);

        handleClose();
        setNewClub({ name: '', url: '', category: '', owner: currentUser.email });
        console.log("newClub", newClub, currentUser.displayName, currentUser)
      } catch (error) {
        console.error("Error adding club:", error);
      }
    }
  };

  // Separate clubs the user is a member of from those they are not
  const clubsIAmMemberOf = clubs.filter(club => userMemberships.some(membership => membership.clubId === club.id));
  const clubsNotAMemberOf = clubs.filter(club => !userMemberships.some(membership => membership.clubId === club.id));

  return (
    <>
      <ResponsiveAppBar />
      <Box sx={{ display: "flex", height: "100%" }}>
        <Sidenav />
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="category-select-label">Categories</InputLabel>
            <Select
              labelId="category-select-label"
              multiple
              value={selectedCategories}
              onChange={handleCategoryChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  <Checkbox checked={selectedCategories.includes(category)} />
                  <ListItemText primary={category} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button variant="contained" sx={{ mt: 2 }} onClick={handleOpen}>
            Create Club
          </Button>

          <Box sx={{ marginTop: 2 }}>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Typography variant="h6">My Clubs</Typography>
                <Grid container spacing={2}>
                  {clubsIAmMemberOf.map((club) => (
                    <Grid item xs={12} sm={6} md={2} key={club.id}>
                      <Box
                        sx={{
                          padding: 2,
                          textAlign: 'center',
                          borderRadius: '8px',
                          border: '1px solid #ddd',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          transition: 'transform 0.3s, box-shadow 0.3s',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                          },
                        }}
                      >
                        <a href={`club?id=${club.id}`} rel="noopener noreferrer">
                          <img
                            src={club.url}
                            alt={club.name}
                            style={{
                              maxWidth: '150px',
                              height: '150px',
                              objectFit: 'cover',
                              cursor: 'pointer',
                              borderRadius: '50%',
                              border: '3px solid #f0f0f0',
                              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)',
                            }}
                          />
                        </a>
                        <Box sx={{ marginTop: 1, fontWeight: 'bold', color: '#333' }}>{club.name}</Box>
                      </Box>
                    </Grid>

                  ))}
                </Grid>

                <Typography variant="h6" sx={{ marginTop: 4 }}>Club Spotlights</Typography>
                <Grid container spacing={2}>
                  {clubsNotAMemberOf.map((club) => (
                    <Grid item xs={12} sm={6} md={2} key={club.id}>
                      <Box
                        sx={{
                          padding: 2,
                          textAlign: 'center',
                          borderRadius: '8px',
                          border: '1px solid #ddd',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          transition: 'transform 0.3s, box-shadow 0.3s',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                          },
                        }}
                      >
                        <a href={`club?id=${club.id}`} rel="noopener noreferrer">
                          <img
                            src={club.url}
                            alt={club.name}
                            style={{
                              maxWidth: '150px',
                              height: '150px',
                              objectFit: 'cover',
                              cursor: 'pointer',
                              borderRadius: '50%',
                              border: '3px solid #f0f0f0',
                              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)',
                            }}
                          />
                        </a>
                        <Box sx={{ marginTop: 1, fontWeight: 'bold', color: '#333' }}>{club.name}</Box>
                      </Box>
                    </Grid>

                  ))}
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {/* Modal for creating a new club */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}
        >
          <h2>Create a New Club</h2>
          <TextField
            fullWidth
            label="Club Name"
            name="name"
            value={newClub.name}
            onChange={handleInputChange}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Club Image URL"
            name="url"
            value={newClub.url}
            onChange={handleInputChange}
            sx={{ marginBottom: 2 }}
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Category</InputLabel>
            <Select
              name="category"
              value={newClub.category}
              onChange={handleInputChange}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button variant="contained" onClick={handleCreateClub}>
            Create
          </Button>
        </Box>
      </Modal>
    </>
  );
}
