import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Sidenav from "../components/NavBars/Sidenav";
import ResponsiveAppBar from "../components/NavBars/ResNav";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import { collection, doc, getDoc, setDoc, Timestamp, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { AuthContext } from "../components/Auth/Auth";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation } from 'react-router-dom';


export default function Club() {
  // const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const id = queryParams.get('id');

  const { currentUser } = useContext(AuthContext);
  const [clubData, setClubData] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [members, setMembers] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchClubData = async () => {
      const clubDoc = doc(db, "clubs", id);
      const clubSnapshot = await getDoc(clubDoc);
      if (clubSnapshot.exists()) {
        setClubData(clubSnapshot.data());
      } else {
        console.error("No such document!");
      }
    };

    const checkMembership = async () => {
      if (currentUser) {
        const membershipsCollection = collection(db, "memberships");
        const membershipsQuery = query(membershipsCollection, where("user", "==", currentUser.email), where("clubId", "==", id));
        const membershipsSnapshot = await getDocs(membershipsQuery);
        setIsMember(!membershipsSnapshot.empty);
      }
    };

    const fetchMembers = async () => {
      const membershipsCollection = collection(db, "memberships");
      const membersQuery = query(membershipsCollection, where("clubId", "==", id));
      const membersSnapshot = await getDocs(membersQuery);
      const memberEmails = membersSnapshot.docs.map(doc => doc.data().user);
      setMembers(memberEmails);
    };

    fetchClubData();
    checkMembership();
    fetchMembers();
  }, [id, currentUser]);

  const handleJoinClub = async () => {
    if (currentUser && clubData) {
      const membershipRef = doc(db, "memberships", `${currentUser.email}_${id}`);
      await setDoc(membershipRef, {
        user: currentUser.email,
        clubId: id,
        dateJoined: Timestamp.fromDate(new Date())
      });
      setIsMember(true);
      // Add the new member to the list of members
      setMembers(prevMembers => [...prevMembers, currentUser.email]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!clubData) return <Typography>Loading...</Typography>;

  return (
    <>
      <ResponsiveAppBar />
      <Box sx={{ display: "flex", height: "100%" }}>
        <Sidenav />
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            {/* Club Image */}
            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
              <img
                src={clubData.url}
                alt={`${clubData.name} logo`}
                style={{ maxWidth: "150px", height: "150px", borderRadius: "10px" }}
              />
            </Box>

            {/* Club Name */}
            <Typography
              variant="h4"
              component="h3"
              gutterBottom
              sx={{ textAlign: "center" }} // Center the name
            >
              {clubData.name}
            </Typography>

            {/* Club Category */}
            <Typography
              variant="h5"
              component="h2"
              color="textSecondary"
              gutterBottom
              sx={{ textAlign: "center" }} // Center the category text
            >
              {clubData.category}
            </Typography>



            <Box sx={{ display: "flex", marginTop: 4, justifyContent: "center" }}>
              {currentUser && clubData.owner === currentUser.email ? (
                <Typography variant="body1" paragraph>
                  You are the owner of this club.
                </Typography>
              ) : isMember ? (
                <Typography variant="body1" paragraph>
                  You are already a member of this club.
                </Typography>
              ) : (
                <Button variant="contained" color="primary" onClick={handleJoinClub}>
                  Join Club
                </Button>
              )}
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="club tabs">
                <Tab label="Members" />
                <Tab label="Projects" />
                <Tab label="Posts" />
                <Tab label="Jobs" />
              </Tabs>
            </Box>

            {tabValue === 0 && (
              <Box sx={{ padding: 2 }}>
                {/* Club Owner */}
                <Typography variant="body1" paragraph>
                  <strong>Owner:</strong> {clubData.owner}
                </Typography>
                <Typography variant="h6" component="h2" gutterBottom>
                  Members:
                </Typography>
                <ul>
                  {members.map((member, index) => (
                    <li key={index}>{member}</li>
                  ))}
                </ul>
              </Box>
            )}

            {tabValue === 1 && (
              <Box sx={{ padding: 2 }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Projects:
                </Typography>
                <Typography>No projects available.</Typography>
              </Box>
            )}

            {tabValue === 2 && (
              <Box sx={{ padding: 2 }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Posts:
                </Typography>
                <Typography>No posts available.</Typography>
              </Box>
            )}

            {tabValue === 3 && (
              <Box sx={{ padding: 2 }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Jobs:
                </Typography>
                <Typography>No jobs available.</Typography>
              </Box>
            )}


          </Paper>
        </Box>
      </Box>


    </>
  );
}
